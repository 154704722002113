import React from 'react';
import { Services } from '../components/Services';
import { Testimonials } from '../components/Testimonials';

export const ServicesPage = () => {
  return (
    <div className="pt-16">
      <Services />
      <Testimonials />
    </div>
  );
};